import * as React from "react";
import "../../Styles.scss";
import ErrorBoundary from "../../common/ErrorBoundary";
import UpArrow from "../../common/UpArrow";
import {Button} from "semantic-ui-react";

export interface IVacancy {
    id: string,
    name: string,
    experience: string,
    employment: string,
    picture: any,
    itemGroups: ItemGroups[]
}

interface ItemGroups {
    header: string,
    items?: String[]
}

export default class Vacancy extends React.Component {

    openVacancy = (e: any) => {
        window.open(`https://hh.ru/vacancy/${e.target.id}`);
    }

    renderVacancy = (vacancy: any) => {
        return (
            <div className="vacancies">
                <div className="vacancyHeader">
                    {vacancy.name}
                </div>
                <div className="displayFlexVacancy">
                    <img src={vacancy.picture} height="80px" className="humanIconVacancy" alt=""/>
                    <div className="displayBlock">
                        <div className="vacancyOption"><b>Опыт:&nbsp;</b>{vacancy.experience}</div>
                        <div className="vacancyOption"><b>Занятость:&nbsp;</b>{vacancy.employment}</div>
                    </div>
                </div>
                {this.renderGroups(vacancy.itemGroups)}
                <Button basic color='blue' className="hh" onClick={this.openVacancy} id={`${vacancy.id}`}>Посмотреть вакансию на hh</Button>
            </div>
        )
    }

    renderGroups = (groups: any) => {
        return groups.map((group: any, i: number) => {
            return (
                <div key={i}>
                    <div className="vacancyOption"><b>{group.header}</b></div>
                    <ul className="tasks">
                        {this.renderItems(group.items, group.items.length)}
                    </ul>
                </div>
            )
        })
    }

    renderItems = (items: String[], length: number) => {
        return items.map((item: any, i: number) => {
            if (i === length - 1) {
                return <li className="taskItem" key={i}>{item}.</li>
            } else {
                return <li className="taskItem" key={i}>{item};</li>
            }
        })
    }

    render() {
        return (
            <>
                <ErrorBoundary>
                    <div className="main">
                        {this.renderVacancy(this.props.children)}
                    </div>
                </ErrorBoundary>
                <UpArrow />
            </>
        )
    }
}

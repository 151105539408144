import * as React from "react";
import "../Styles.scss";
import point from './placeholder.png'
import phone from './phone.png'
import mail from './mail.png'
import UpArrow from "../common/UpArrow";
import ErrorBoundary from "../common/ErrorBoundary";

export default class Contacts extends React.Component {
    openMap = (e: any) => {
        window.open(e.target.id);
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="main">
                    <div className="bookContainer">
                        <div className="text">
                            <img src={point} height="40px" className="marginRight10" alt=""/>
                            <span className="mapLink" id="https://yandex.ru/maps/-/CCUUnNDf3B" onClick={this.openMap}>
                                г. Москва, улица Фридриха Энгельса, 75с11
                            </span>
                        </div>
                        <div className="map">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3A6bab9993c285aa127b1a575640b676e5e6fc456abcc803fbe74746d40ed4fa27&amp;source=constructor"
                                width="500" height="400" frameBorder="0" title="map"></iframe>
                        </div>
                        <div className="text">
                            <img src={phone} height="40px" className="marginRight10" alt=""/>
                            &nbsp; Телефон: &nbsp;
                            +7 (495) 797-30-31
                        </div>
                        <div className="text">
                            <img src={mail} height="40px" className="marginRight10" alt=""/>
                            &nbsp; Почта: &nbsp;
                            it@srgroup.ru
                        </div>
                        <div className="text displayFlex">
                            Сайт группы компаний: &nbsp;
                            <span className="siteLink" id="https://www.srgroup.ru/" onClick={this.openMap}>
                                srgroup.ru
                            </span>
                        </div>
                        <div className="text displayFlex">
                            Сайт о продуктах для клиентов и заказчиков: &nbsp;
                            <span className="siteLink" id="https://srg-it.ru/" onClick={this.openMap}>
                                srg-it.ru
                            </span>
                        </div>
                        <UpArrow />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

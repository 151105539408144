import * as React from "react";
import {Icon} from "semantic-ui-react";
import "../Styles.scss";
import "./HomePage.scss";
import lamp from './img/lamp.png'
import mortgage from './img/whoWeAre/mortgage.png'
import robot2 from './img/whoWeAre/robot.png'
import transaction from './img/whoWeAre/transaction.png'
import flat from './img/whoWeAre/flat.png'
import database from './img/whoWeAre/database.png'
import mongo from './img/whatWeUse/mongo.png'
import proxySql from './img/whatWeUse/proxysql.png'
import mySql from './img/whatWeUse/mysql.png'
import hadoop from './img/whatWeUse/hadoop.png'
import python from './img/whatWeUse/python.png'
import scipy from './img/whatWeUse/scipy.png'
import pandas from './img/whatWeUse/pandas.png'
import anaconda from './img/whatWeUse/anaconda.png'
import numpy from './img/whatWeUse/numpy.png'
import elastic from './img/whatWeUse/elastic.svg'
import java from './img/whatWeUse/java.png'
import spring from './img/whatWeUse/spring.png'
import kubernetes from './img/whatWeUse/kubernetes.png'
import vault from './img/whatWeUse/vault.png'
import kiali from './img/whatWeUse/kiali.png'
import istio from './img/whatWeUse/istio.png'
import docker from './img/whatWeUse/docker.png'
import flagger from './img/whatWeUse/flagger.png'
import helm from './img/whatWeUse/helm.png'
import redis from './img/whatWeUse/redis.png'
import rabbit from './img/whatWeUse/rabbitmq.png'
import go from './img/whatWeUse/go.png'
import zabbix from './img/whatWeUse/zabbix.png'
import powerBi from './img/whatWeUse/powerBi.png'
import grafana from './img/whatWeUse/grafana.png'
import jenkins from './img/whatWeUse/jenkins.png'
import jira from './img/whatWeUse/jira.png'
import puppet from './img/whatWeUse/puppet.png'
import mobxImg from './img/whatWeUse/mobx.svg'
import react from './img/whatWeUse/react.png'
import ts from './img/whatWeUse/ts.png'
import {Popup} from "semantic-ui-react";
import UpArrow from "../common/UpArrow";
import ErrorBoundary from "../common/ErrorBoundary";

interface News {
    name: string,
    href: string
}

export default class HomePage extends React.Component {
    redirect = (e: any) => {
        window.location.href = `/${e.target.id}`;
    }

    openArticle = (e: any) => {
        window.open(e.target.id);
    }

    news: News[] = [
        {
            name: 'Онлайн-страхование ипотеки: как выгодно и быстро оформить полис',
            href: 'https://realty.rbc.ru/news/60910c4c9a7947682f6607af'
        },
        {
            name: 'Аналитики назвали города России с сильнее всего подорожавшими квартирами',
            href: 'https://realty.rbc.ru/news/603fdf809a7947dd93d3613c'
        },
    ]

    renderNews = (news: News[]) => {
        return (
            <div className="news">
                <div className="text homePageText headerMargin">
                    <b>О НАС ПИШУТ</b>
                </div>
                <div className="newsText">
                    <div className="newsTextInner">
                        {
                            news.map(newsItem => {
                                return (
                                    <div className="newsTextItem" key={newsItem.href}>
                                        <Icon name="circle" className="circle" />
                                        <span id={newsItem.href} onClick={this.openArticle}>
                                            {newsItem.name}
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <ErrorBoundary>
            <div className="main">
                <div className="text homePageText">
                    <img src={lamp} height="40px" className="lampIcon" alt=""/>
                    <b>НИКТО, КРОМЕ НАС, В ЭТОЙ КОМНАТЕ НЕ ПОЧИНИТ СВЕТ</b>
                </div>
                <div className="text">
                    Мы - разработчики группы компаний SRG. Мы создали этот сайт для того, чтобы познакомить тебя с
                    нашим IT-отделом.
                </div>

                <div className="homePageContainer">
                    <div className="whoWeAre">
                        <div className="text homePageText headerMargin">
                            <b>КТО МЫ?</b>
                        </div>
                        <div className="whoWeAreInner">
                            <div className="displayBlock">
                                <div className="container marginRight">
                                    <div className="homeText">
                                        <div>
                                            Мы знаем практически <b>все</b> о <b>каждой квартире</b>, которая была выставлена на продажу за последние 8 лет.
                                        </div>
                                        <img src={flat} className="homePageImg flat" alt="flat" />
                                    </div>
                                </div>
                                <div className="container mortgageContainer">
                                    <div className="homeText ">
                                        <div>
                                            <b className="number">80%</b> ипотечных сделок проходит через нас
                                        </div>
                                        <img src={mortgage} className="homePageImg mortgage" alt="mortgage" />
                                    </div>
                                </div>
                            </div>

                            <div className="container mAuto">
                                <div className="homeText automation">
                                    <div>
                                        <b>Мы автоматизировали:</b>
                                        <ul>
                                            <li>оценку недвижимости</li>
                                            <li>оценку движимости (авто)</li>
                                            <li>ипотечное страхование</li>
                                            <li>электронную регистрацию</li>
                                        </ul>
                                    </div>
                                    <img src={robot2} className="homePageImg robot2" alt="robot2" />
                                </div>
                            </div>

                            <div className="displayBlock whoWeAreLastBlock">
                                <div className="container mrAuto">
                                    <div className="homeText">
                                        <div className="homeTextInner">
                                            В нашей базе<br /><b className="number">1,7 млрд</b><br />объявлений
                                        </div>
                                        <img src={database} className="homePageImg database" alt="robot2" />
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="homeText ad">
                                        <div className="homeTextInner">
                                            Мы обрабатываем<br /><b className="number">25 млн</b><br />объявлений в сутки
                                        </div>
                                        <img src={transaction} className="homePageImg transaction" alt="transaction" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ourGoal">
                        <div className="text homePageText headerMargin">
                            <b>НАША ЦЕЛЬ</b>
                        </div>
                        <div className="ourGoalText">
                            Мы меняем процесс ипотечного кредитования, разрабатывая и внедряя собственные цифровые решения.
                            <br/>
                            <br/>
                            Мы создаем удобный и высокотехнологичный сервис для всех участников на каждом этапе кредитной сделки.
                        </div>
                    </div>

                    <div className="whatWeUse">
                        <div className="text homePageText headerMargin">
                            <b>ЧТО МЫ ИСПОЛЬЗУЕМ?</b>
                        </div>
                        <div className="whatWeUseRow">
                            <div className="container bdContainer">
                                <div className="containerTitle"><b>БАЗЫ ДАННЫХ</b></div>
                                <Popup
                                    trigger={<img src={mySql} className="homePageImg mySql" alt="mysql" />}
                                    content='MySQL'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={mongo} className="homePageImg mongo" alt="mongo" />}
                                    content='MongoDB'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={proxySql} className="homePageImg proxySql" alt="proxysql" />}
                                    content='ProxySQL'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={elastic} className="homePageImg elastic" alt="elastic" />}
                                    content='Elastic'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={redis} className="homePageImg redis" alt="redis" />}
                                    content='Redis'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={rabbit} className="homePageImg rabbit" alt="rabbit" />}
                                    content='RabbitMQ'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                            </div>

                            <div className="container javaContainer">
                                <div className="containerTitle"><b>БЭКЕНД</b></div>
                                <Popup
                                    trigger={<img src={java} className="homePageImg java2" alt="java" />}
                                    content='Java'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={spring} className="homePageImg spring" alt="spring" />}
                                    content='Spring'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={go} className="homePageImg go" alt="go" />}
                                    content='GO'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={python} className="homePageImg pythonLang" alt="python" />}
                                    content='Python'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                            </div>

                            <div className="container kubernetesContainer">
                                <div className="containerTitle"><b>ИНФРАСТРУКТУРА</b></div>
                                <Popup
                                    trigger={<img src={kubernetes} className="homePageImg kubernetes2" alt="kubernetes" />}
                                    content='Kubernetes'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={vault} className="homePageImg vault" alt="vault" />}
                                    content='Vault'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={kiali} className="homePageImg kiali" alt="kiali" />}
                                    content='Kiali'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={istio} className="homePageImg istio" alt="istio" />}
                                    content='Istio'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={docker} className="homePageImg dockerImg" alt="docker" />}
                                    content='Docker'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={flagger} className="homePageImg flagger" alt="flagger" />}
                                    content='Flagger'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={helm} className="homePageImg helm" alt="helm" />}
                                    content='Helm'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                            </div>

                            <div className="container hadoopContainer">
                                <div className="containerTitle bigDataTitle"><b>BIG DATA</b></div>
                                <Popup
                                    trigger={<img src={hadoop} className="homePageImg hadoop" alt="hadoop" />}
                                    content='Hadoop'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={python} className="homePageImg python" alt="python" />}
                                    content='Python'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={scipy} className="homePageImg scipy" alt="scipy" />}
                                    content='SciPy'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={pandas} className="homePageImg pandas" alt="pandas" />}
                                    content='Pandas'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={anaconda} className="homePageImg anaconda" alt="anaconda" />}
                                    content='Anaconda'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={numpy} className="homePageImg numpy" alt="numpy" />}
                                    content='NumPy'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                            </div>

                            <div className="container reactContainer">
                                <div className="containerTitle frontendTitle"><b>ФРОНТЕНД</b></div>
                                <Popup
                                    trigger={<img src={react} className="homePageImg reactImg" alt="react" />}
                                    position='bottom center'
                                    size="large"
                                    on='hover'
                                    content='React'
                                />
                                <Popup
                                    trigger={<img src={ts} className="homePageImg ts" alt="ts" />}
                                    position='bottom center'
                                    size='large'
                                    on='hover'
                                    content='TypeScript'
                                />
                                <Popup
                                    trigger={<img src={mobxImg} className="homePageImg mobxImg" alt="mobxImg" />}
                                    position='bottom center'
                                    size='large'
                                    on='hover'
                                    content='MobX'
                                />
                            </div>

                            <div className="container monitoringContainer">
                                <div className="containerTitle monitoringTitle"><b>МОНИТОРИНГ</b></div>
                                <Popup
                                    trigger={<img src={zabbix} className="homePageImg zabbix" alt="zabbix" />}
                                    content='Zabbix'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={powerBi} className="homePageImg powerBi" alt="powerBi" />}
                                    content='PowerBI'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={grafana} className="homePageImg grafanaImg" alt="grafana" />}
                                    content='Grafana'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={jenkins} className="homePageImg jenkins2" alt="jenkins" />}
                                    content='Jenkins'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={jira} className="homePageImg jira2" alt="jira" />}
                                    content='Jira'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />
                                <Popup
                                    trigger={<img src={puppet} className="homePageImg puppet2" alt="puppet" />}
                                    content='Puppet'
                                    on='hover'
                                    position='bottom center'
                                    size='large'
                                />

                            </div>
                        </div>
                    </div>

                    {this.renderNews(this.news)}
                </div>

                <UpArrow />
            </div>
            </ErrorBoundary>
        )
    }
}

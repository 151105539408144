import * as React from "react";
import "../../Styles.scss";
import productOwner from "../product-owner.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy42344467 extends React.Component {

    vacancy: IVacancy = {
        id: '42344467',
        name: 'Product Owner',
        experience: '3 - 6 лет',
        employment: 'полная занятость, полный день',
        picture: productOwner,
        itemGroups: [
            {
                header: 'Задачи:',
                items: [
                    'Доработка/трансформация продукта в сфере диагностирования и развития поведенческих характеристик работников',
                    'Управление проектами по оказанию консалтинговых услуг (на старте вовлечение в текущие проекты в виде консультирования' +
                    ' по вопросам работоспособности бизнес- процессов в сфере безопасности)',
                    'Управление эффективностью и производительностью проектных групп и проектов (на старте эффективное управление' +
                    ' внутренними/внешними ресурсами в рамках бюджета по конкретному проекту)',
                    'Развитие продаж в продвижении HALP',
                    'Формирование стратегии развития продуктов культуры безопасности для российских компаний',
                    'Участие в подготовке и обучении менеджеров для продажи услуг',
                    'Участие в PR мероприятиях'
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Опыт работы в выводе продуктов на рынок/start-up',
                    'Опыт руководства командой от 5-ти человек',
                    'Умения разрабатывать финансовую модель продукта',
                    'Оперативное управление затратами в рамках модели',
                    'Управление компетенциями, формирование структуры, работа с внешними экспертами и подрядчиками',
                    'Высокий уровень инициативности',
                    'Процессный подход в построении систем',
                    'Проектное управление',
                    'Понимание основ психологии (научная база знаний как для руководителя, уровень КПН не нужен)',
                    'Общее понимание технологий производств в сферах: транспорт, добыча, энергетика, связь (техническая грамотность).' +
                    ' Так как работа будет связана с производством, требуется способность быстро понимать основные процесс и оборудование для ведения переговоров',
                    'Знание Английского языка желательно'
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Работа в стабильной, динамично развивающейся компании',
                    'Работа в команде профессионалов - экспертов',
                    'Развитие до директора направления',
                    'Оформление по ТК с первого дня',
                    'График работы 5/2 с 09:00 до 18:00',
                    'Конкурентная и стабильная заработная плата'
                ]
            },
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

import * as React from "react";
import "../../Styles.scss";
import analyst2 from "../analyst2.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy44792131 extends React.Component {

    vacancy: IVacancy = {
        id: '44792131',
        name: 'Junior Data Scientist',
        experience: 'не требуется',
        employment: 'полная занятость, полный день',
        picture: analyst2,
        itemGroups: [
            {
                header: 'Обязанности:',
                items: [
                    'Разработка и улучшение алгоритмов обработки больших данных с использованием Hadoop,' +
                    ' Hbase, Spark (дедубликация, кластеризация, очистка от выбросов, обогащение,' +
                    ' предсказание, обработка текста на естественном языке, распознавание адреса)',
                    'Разработка и улучшение алгоритмов автоматической оценки недвижимости: задачи' +
                    ' регрессии, задачи классификации, краткосрочное прогнозирование',
                    'Разработка сервисов «под ключ» - от получения и обработки данных до деплоя и поддержки в проде',
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Знание Python, или Java, или С++',
                    'Хорошее знание мат.статистики, теории вероятностей',
                    'Знание стандартных алгоритмов и структур данных',
                ]
            },
            {
                header: 'Желательно:',
                items: [
                    'Опыт работы с машинным обучением (кластеризация, классификация, регрессия)',
                    'Опыт работы с Hadoop, HBase или другими системами обработки больших данных'
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Оформление по ТК РФ',
                    'Заработная плата и размер ежеквартальных премий обсуждаются с успешным кандидатом',
                    'Офис БЦ "Пальмира", м. Бауманская/Электрозаводская',
                    'Гибкое начало рабочего дня',
                    'Дружеская атмосфера'
                ]
            }
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

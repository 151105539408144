import * as React from "react";
import "../../Styles.scss";
import productOwner2 from "../product-owner2.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy44792739 extends React.Component  {

    vacancy: IVacancy = {
        id: '44792739',
        name: 'Руководитель продукта/Product Owner',
        experience: '3 – 6 лет',
        employment: 'полная занятость, полный день',
        picture: productOwner2,
        itemGroups: [
            {
                header: 'Зоны ответственности:',
                items: [
                    'Рост дохода по продукту',
                    'Развитие продукта на основе цифр, фактов, product vision и подтвержденного product-market fit',
                    'Управление взаимоотношениями с крупными Банками и Страховыми компаниями',
                    'Формирование облика команды, которая может эффективно сопровождать это направление бизнеса',
                ]
            },
            {
                header: 'Задачи:',
                items: [
                    'Укрепление позиций продукта для существующих Клиентов (рост конверсии и развитие новых каналов)',
                    'Поиск и развитие новых каналов для применения продукта',
                    'Сбор и анализ метрик продукта, построение выводов и регулярная отчетность по ним',
                    'Гармонизация коммерческой политики',
                    'Построение и защита тактики и 2-3 летней стратегии продукта',
                    'Оценка потенциала источников доходов в деньгах',
                    'Развитие внутренней и внешней продуктовой аналитики',
                    'Выявление, проработка и приоритизация требований',
                    'Наполнение бэклога продукта идеями, формулировка бизнес-требований',
                    'Взаимодействие с командой разработки и поддержки',
                    'Анализ рынка и конкурентов',
                    'Донесение информации о планах по продукту до сотрудников компании',
                    'Поиск путей оптимизации процессов поддержки и сопровождения продукта в течение жизненного цикла',
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Опыт работы в роли Business Development / Product Owner от 2-х лет',
                    'Опыт развития/сопровождения продуктового бизнеса с оборотом от 50 млн.руб. в год',
                    'Опыт или понимание специфики работы в B2B сегменте: Банки, Страховщики, их партнеры или другие участники рынка ипотеки',
                    'Опыт взаимодействия с техническими специалистами: разработка, поддержка',
                    'Высокий уровень самостоятельности и предпринимательский склад ума',
                    'Умение слушать и слышать собеседника, вести переговоры и проводить интервью',
                    'Развитые коммуникативные и презентационные навыки',
                    'Высшее техническое/экономическое образование',
                    'Владение английским на уровне не ниже intermediate'
                ]
            },
            {
                header: 'Преимуществом будет:',
                items: [
                    'Опыт построения продуктовой аналитики (у нас Power BI)',
                    'Опыт работы с инструментами постановки задач (у нас JIRA, Confluence)',
                    'Технический бэкграунд (понимание принципов работы web-приложений)',
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Высокая заработная плата: оклад + бонусы',
                    'График работы 5/2, с 9:00 до 18:00',
                    'Комфортный офис (БЦ "Пальмира") м. Бауманская/ Электрозаводская',
                    'Возможность работать в атмосфере открытости и доверия среди единомышленников',
                ]
            },
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}
import * as React from "react";
import "../Styles.scss";
import point from './placeholder.png'
import phone from './phone.png'
import mail from './mail.png'
import ErrorBoundary from "../common/ErrorBoundary";

export default class Footer extends React.Component {

    openMap = (e: any) => {
        window.open(e.target.id);
    }

    render() {
        return (
            <ErrorBoundary>
                <footer>
                    <div className="footerText">
                        <img src={point} className="marginRight10 footerImgHeight" alt=""/>
                        <span className="mapLink" id="https://yandex.ru/maps/-/CCUUnNDf3B" onClick={this.openMap}>
                            г. Москва, улица Фридриха Энгельса, 75с11
                        </span>
                    </div>
                    <div className="footerText">
                        <img src={phone} className="marginRight10 footerImgHeight" alt=""/>
                        Телефон: &nbsp;
                        <a href="tel:+74957973031">+7 (495) 797-30-31</a>
                    </div>
                    <div className="footerText">
                        <img src={mail} className="marginRight10 footerImgHeight" alt=""/>
                        Почта: &nbsp;
                        <a href="mailto:it@srgroup.ru">it@srgroup.ru</a>
                    </div>
                </footer>
            </ErrorBoundary>
        )
    }
}

import * as React from "react";
import "../Styles.scss";
import ImageGalleryOffice from './ImageGalleries/ImageGalleryOffice';
import ImageGalleryPeople from './ImageGalleries/ImageGalleryPeople';
import UpArrow from "../common/UpArrow";
import ErrorBoundary from "../common/ErrorBoundary";

export default class Office extends React.Component {
    openBook = (e: any) => {
        window.open(e.target.id);
    }

    render() {
        return (
            <ErrorBoundary>
            <div className="main">
                <div className="office">
                    <div className='textAboutSrg'>Приходи к нам в команду, не выходя из дома!</div>
                    <div className='textAboutSrg'>А если хочешь - приходи в наш офис, где мы все сделали так, чтобы нашим
                        математикам и разработчикам было комфортно в творческой обстановке
                        и в окружении настоящих профессионалов.</div>

                    <div className="officeLabel officeHeaders">отдыхаем</div>
                    <div className="gallery">
                        <ImageGalleryPeople />
                    </div>

                    <div className="officeLabel officeHeaders">работаем</div>
                    <div className='gallery minHeight'>
                        <ImageGalleryOffice />
                    </div>
                </div>

                <UpArrow />
            </div>
            </ErrorBoundary>
        )
    }
}

import ImageGallery from 'react-image-gallery';
import * as React from "react";
import "../../Styles.scss";
import office1full from '../photos/office/office1full.jpg';
import office1small from '../photos/office/office1small.jpg';
import office3full from '../photos/office/office3full.jpg';
import office3small from '../photos/office/office3small.jpg';
import office5full from '../photos/office/office5full.jpg';
import office5small from '../photos/office/office5small.jpg';
import office6full from '../photos/office/office6full.jpg';
import office6small from '../photos/office/office6small.jpg';
import office7full from '../photos/office/office7full.jpg';
import office7small from '../photos/office/office7small.jpg';
import office8full from '../photos/office/office8full.jpg';
import office8small from '../photos/office/office8small.jpg';
import office9full from '../photos/office/office9full.jpg';
import office9small from '../photos/office/office9small.jpg';
import office10full from '../photos/office/office10full.jpg';
import office10small from '../photos/office/office10small.jpg';
import office11full from '../photos/office/office11full.jpg';
import office11small from '../photos/office/office11small.jpg';
import ErrorBoundary from "../../common/ErrorBoundary";


export default class ImageGalleryOffice extends React.Component {
    images = [
        {
            original: office6full,
            thumbnail: office6small,
        },
        {
            original: office7full,
            thumbnail: office7small,
        },
        {
            original: office8full,
            thumbnail: office8small,
        },
        {
            original: office9full,
            thumbnail: office9small,
        },
        {
            original: office10full,
            thumbnail: office10small,
        },
        {
            original: office11full,
            thumbnail: office11small,
        },
        {
            original: office5full,
            thumbnail: office5small,
        },
        {
            original: office1full,
            thumbnail: office1small,
        },
        {
            original: office3full,
            thumbnail: office3small,
        },
    ];

    render() {
        return (
            <ErrorBoundary>
                <ImageGallery items={this.images} />
            </ErrorBoundary>
        )
    }
}

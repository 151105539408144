import * as React from "react";
import * as ReactDOM from "react-dom";
import {configure} from "mobx";

import App from "./App";
import './index.css';
import 'semantic-ui-css/semantic.min.css'

import {locale} from "moment";
import "moment/locale/ru";
locale("ru");



if (process.env.NODE_ENV === "production") {
    configure({
        enforceActions: "never",
    });
} else {
    configure({
        enforceActions: "never",
    });
}

let MOUNT_NODE: HTMLElement | null;
MOUNT_NODE = document.getElementById("root");

ReactDOM.render(<App />, MOUNT_NODE);
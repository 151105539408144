import * as React from "react";
import "../Styles.scss";
import UpArrow from "../common/UpArrow";
import ErrorBoundary from "../common/ErrorBoundary";

export default class Stack extends React.Component {
    render() {
        return (
            <ErrorBoundary>
            <div className="main">
                <div className="stack">
                    <label className="stackLabel">Как мы работаем</label>
                    <ul className="stackGroup">
                        <li className="itemStack">
                            Мы стараемся писать сначала документацию, и только потом - код. Документацию мы храним в
                            Confluence и MediaWiki
                        </li>
                        <li className="itemStack">
                            После того, как у нас описан дизайн продукта или фичи, мы планируем спринты
                            (мы работаем по Scrum, спринты по 2 недели)
                        </li>
                        <li className="itemStack">
                            В качестве системы внутреннего документооборота мы используем JIRA, где
                            создаем задачи и описываем найденные баги
                        </li>
                        <li className="itemStack">
                            Развертывание системы осуществляется с использованием Jenkins
                            и Puppet
                        </li>
                        <li className="itemStack">
                            В качестве средств мониторинга мы используем Graphana, Kibana, Prometeus и Zabbix
                        </li>
                        <li className="itemStack">
                            Хотим и потихоньку переезжаем частью сервисов в Kubernetes
                        </li>
                        <li className="itemStack">
                            В планах внедрить распределенную файловую систему типа Ceph
                        </li>
                    </ul>

                    <label className="stackLabel">Java-приложения</label>
                    <ul className="stackGroup">
                        <li className="itemStack">
                            Основой наших продуктов является Java 8/11 и Spring (в т.ч. Spring Boot, Spring Data, Spring Security)
                        </li>
                        <li className="itemStack">
                            В качестве баз данных мы используем MySQL, MongoDB, Redis, ElasticSearch
                        </li>
                        <li className="itemStack">
                            Мы используем микросервисную архитектуру; сервисы взаимодействуют через REST
                        </li>
                        <li className="itemStack">
                            Для UI используется ReactJS
                        </li>
                        <li className="itemStack">
                            Поставка Java-приложений осуществляется в виде deb-пакетов через APT-репозиторий
                        </li>
                    </ul>

                    <label className="stackLabel">Математика, алгоритмы и обработка данных</label>
                    <ul className="stackGroup">
                        <li className="itemStack">
                            Сервисы развернуты в Docker-контейнерах
                        </li>
                    </ul>
                </div>
                <UpArrow />
            </div>
            </ErrorBoundary>
        )
    }
}

import * as React from "react";
import "../../Styles.scss";
import javaDeveloper from "../java-developer.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy44768073 extends React.Component {

    vacancy: IVacancy = {
        id: '44768073',
        name: 'Senior Java developer',
        experience: '3–6 лет',
        employment: 'полная занятость, полный день',
        picture: javaDeveloper,
        itemGroups: [
            {
                header: 'Задачи:',
                items: [
                    'Наравне с коллегами нести ответственность за анализ, проектирование и внедрение бизнес проектов',
                    'Самостоятельно согласовывать и внедрять новые архитектурные и алгоритмические решения',
                    'Обучать коллег и учиться самому посредством код-ревью, командных мероприятий и личного общения',
                    'Проактивно участвовать в формировании планов команды',
                ]
            },
            {
                header: 'Что мы используем:',
                items: [
                    'Основой наших продуктов является Java8 и Spring (в т.ч. SpringBoot, SpringData, SpringSecurity)',
                    'Математика наших роботов написана на Python с использованием различных алгоритмов машинного обучения',
                    'В качестве баз данных мы используем MySQL, MongoDB, Redis, ElasticSearch',
                    'Для обработки больших данных применяется Hadoop и HBase',
                    'В качестве средств мониторинга - Graphana, Kibana и Zabbix',
                    'Развертывание системы осуществляется с использованием Jenkins и Puppet',
                    'Web-часть взаимодействует через REST и использует ReactJS, Thymeleaf',
                    'Часть сервисов развернуты в Docker-контейнерах',
                ]
            },
            {
                header: 'Как мы работаем:',
                items: [
                    'У нас используется CD/CI с использованием Jenkins',
                    'В качестве системы внутреннего документооборота мы используем JIRA',
                    'Часть проектов используют Kanban, часть - Scrum',
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Более 3-х лет разрабатываете коммерческие продукты на Java',
                    'Знаете и используете Spring, Spring-boot, Spring data, Hibernate, Redis, возможно, ещё и MongoDB и RabbitMQ',
                    'Знаете, почему и когда нужно писать простой, расширяемый и производительный код',
                    'Владеете средствами коллективной разработки и сборки проектов (Mercurial, Gradle, Jirа, Jenkins)',
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Оформление по ТК РФ',
                    'Белая заработная плата',
                    'Размер ежеквартальных премий обсуждаются с успешным кандидатом',
                    'Офис БЦ "Пальмира", м. Бауманская/Электрозаводская (в шаговой доступности)',
                    'Гибкое начало рабочего дня ( с 9 до 11ч)',
                    'Возможен частично-удаленный формат работы',
                    'Реальные возможности карьерного роста. Мы заинтересованы в том, чтобы люди в нашей команде росли и развивались'
                ]
            },
        ]
    }


    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

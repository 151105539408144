import * as React from "react";
import "../Styles.scss";
import {Button} from "semantic-ui-react";
import seniorJava from './senior-java.png';
import javaDeveloper from './java-developer.png'
import juniorJava from './junior-java.png'
import productOwner from './product-owner.png'
import productOwner2 from './product-owner2.png'
import productOwner3 from './product-owner3.png'
import analyst from './analyst.png'
import analyst2 from './analyst2.png'
import analyst3 from './analyst3.png'
import upArrow from './up-arrow.png'
import downArrow from './down-arrow.png'
import {action, makeObservable, observable} from "mobx";
import {observer} from "mobx-react";
import UpArrow from "../common/UpArrow";
import ErrorBoundary from "../common/ErrorBoundary";

interface VacancyGroups {
    group: VacancyGroup
}

interface VacancyGroup {
    header: string,
    id: string,
    vacancies: VacancyPreview[]
}

interface VacancyPreview {
    name: string,
    id: string,
    experience: string,
    employment: string,
    tasks: String[],
    picture: any
}

@observer
export default class Office extends React.Component {
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    constructor(props: any) {
        super(props)
        makeObservable(this);
    }

    @observable scrollTop = false;

    @action handleScroll = () => {
        if (window.pageYOffset >= 91) {
            this.scrollTop = true;
        } else {
            this.scrollTop = false;
        }
    }

    openVacancy = (e: any) => {
        window.location.href = `/vacancies/${e.target.id}`
    }

    openHh = (e: any) => {
        window.open(`https://hh.ru/employer/39563`)
    }

    scroll = (e: any) => {
        const elementId = e.target.className;
        const element = document.getElementById(`${elementId}`);
        const topOffset = 100;
        const elementPosition = element!.getBoundingClientRect().top;
        const offsetPosition = elementPosition - topOffset;
        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    @observable isMenuVisible = true;

    @action hideMenu = () => {
        this.isMenuVisible = false;
    }

    @action showMenu = () => {
        this.isMenuVisible = true;
    }

    vacanciesGroups: VacancyGroups[] = [
        {
            // developers
            group:
                {
                    header: 'Любишь писать, но не книги? Значит тебя заинтересует должность разработчика',
                    id: 'developers',
                    vacancies: [
                        {
                            name: 'Team Lead Java',
                            id: '45303749',
                            experience: '3 - 6 лет',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Управление командой Java разработчиков: найм, развитие команды, поддержание среды, в которой хочется продуктивно трудиться',
                                'Детализация задач на основе бизнес постановок',
                                'Оценка задач и участие в формировании спринта',
                                'Решение архитектурных и технически сложных задач',
                                'Code review',
                                'Совершенствование процессов разработки',
                                'Развитие квалификации разработчиков',
                            ],
                            picture: seniorJava
                        },
                        {
                            name: 'Senior Java developer',
                            id: '44768073',
                            experience: '3–6 лет',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Наравне с коллегами нести ответственность за анализ, проектирование и внедрение бизнес проектов',
                                'Самостоятельно согласовывать и внедрять новые архитектурные и алгоритмические решения',
                                'Обучать коллег и учиться самому посредством код-ревью, командных мероприятий и личного общения',
                                'Проактивно участвовать в формировании планов команды',
                            ],
                            picture: javaDeveloper
                        },
                        {
                            name: 'Java разработчик',
                            id: '44973233',
                            experience: '1 - 3 года',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Участие в разработке архитектуры системы и техническом проектировании',
                                'Оптимизация производительности и развитие серверной логики',
                                'Разработка новых продуктов компании в рамках экосистемы «Ипотека за 1 день»',
                            ],
                            picture: juniorJava
                        },
                    ]
                }
        },
        {
            // product_owners
            group:
                {
                    header: 'Ты не интроверт и готов общаться с заказчиком? Вот вакансия Продакт менеджер.',
                    id: 'product_owners',
                    vacancies: [
                        {
                            name: 'Product Owner',
                            id: '42344467',
                            experience: '3 - 6 лет',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Доработка/трансформация продукта в сфере диагностирования и развития поведенческих' +
                                ' характеристик работников',
                                'Управление проектами по оказанию консалтинговых услуг (на старте вовлечение в текущие' +
                                ' проекты в виде консультирования по вопросам работоспособности бизнес- процессов в' +
                                ' сфере безопасности);',
                                'Управление эффективностью и производительностью проектных групп и проектов (на старте' +
                                ' эффективное управление внутренними/внешними ресурсами в рамках бюджета по конкретному проекту)',
                                'Развитие продаж в продвижении HALP',
                                'Формирование стратегии развития продуктов культуры безопасности для российских компаний',
                                'Участие в подготовке и обучении менеджеров для продажи услуг',
                                'Участие в PR мероприятиях',
                            ],
                            picture: productOwner
                        },
                        {
                            name: 'Руководитель продукта/Product Owner',
                            id: '44792739',
                            experience: '3 – 6 лет',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Укрепление позиций продукта для существующих Клиентов (рост конверсии и развитие новых каналов)',
                                'Поиск и развитие новых каналов для применения продукта',
                                'Сбор и анализ метрик продукта, построение выводов и регулярная отчетность по ним',
                                'Гармонизация коммерческой политики',
                                'Построение и защита тактики и 2-3 летней стратегии продукта',
                                'Оценка потенциала источников доходов в деньгах',
                                'Развитие внутренней и внешней продуктовой аналитики',
                                'Выявление, проработка и приоритизация требований',
                                'Наполнение бэклога продукта идеями, формулировка бизнес-требований',
                                'Взаимодействие с командой разработки и поддержки',
                                'Анализ рынка и конкурентов',
                                'Донесение информации о планах по продукту до сотрудников компании',
                                'Поиск путей оптимизации процессов поддержки и сопровождения продукта в течение жизненного цикла',
                            ],
                            picture: productOwner2
                        },
                        {
                            name: 'Product Owner',
                            id: '43447117',
                            experience: '1 – 3 года',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Разработка и управление стратегией развития IT продукта в рамках направления «Коммерческая недвижимость»',
                                'Постановка задач, участие в планировании спринтов, взаимодействие с разработчиками / внешними подрядчиками и пр',
                                'Соблюдение бюджетных показателей и выполнение поставленных целей, включая монетизацию направления, оптимизацию расходов, увеличение CSI и пр',
                                'Разработка стратегии маркетингового продвижения',
                                'Экспертное сопровождение процесса разработки, тестирование (при необходимости)',
                                'Проведение презентаций продукта, участие во внешних мероприятиях',
                            ],
                            picture: productOwner3
                        },
                    ]
                }
        },
        {
            // analytics
            group:
                {
                    header: 'Фанат больших данных? Тогда должность Data Analyst для тебя.',
                    id: 'analytics',
                    vacancies: [
                        {
                            name: 'Senior Data Scientist/Lead Data Scientist',
                            id: '44107302',
                            experience: '3 – 6 лет',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Разработка архитектурных решений, подбор нужных алгоритмов для улучшения качества автоматической оценки недвижимости:' +
                                ' задачи регрессии, задачи классификации, кластеризационные алгоритмы',
                                'Обработка данных с использованием методов машинного обучения, включая обработку больших' +
                                ' данных с использованием Hadoop, HBase, Spark',
                                'Разработка и внедрение NLP алгоритмов',
                                'Доведение исследований до продуктовых решений и их поддержка'
                            ],
                            picture: analyst
                        },
                        {
                            name: 'Data analyst',
                            id: '45244838',
                            experience: '1 – 3 года',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Построение аналитической отчетности в Excel, PowerBI, Python',
                                'Работа с большими объемами данных из SQL и noSQL баз',
                                'Построение взаимосвязей между различными показателями и поиск аномалий в данных',
                            ],
                            picture: analyst3
                        },
                        {
                            name: 'Junior Data Scientist',
                            id: '44792131',
                            experience: 'не требуется',
                            employment: 'полная занятость, полный день',
                            tasks: [
                                'Разработка и улучшение алгоритмов обработки больших данных с использованием Hadoop,' +
                                ' Hbase, Spark (дедубликация, кластеризация, очистка от выбросов, обогащение,' +
                                ' предсказание, обработка текста на естественном языке, распознавание адреса)',
                                'Разработка и улучшение алгоритмов автоматической оценки недвижимости: задачи' +
                                ' регрессии, задачи классификации, краткосрочное прогнозирование',
                                'Разработка сервисов «под ключ» - от получения и обработки данных до деплоя и поддержки в проде',
                            ],
                            picture: analyst2
                        },
                    ]
                }
        }
    ]

    renderVacanciesGroups = (vacanciesPreview: any) => {
        return vacanciesPreview.map((vacancyPreview: any) => {
            return (
                <div key={`${vacancyPreview.group.id}`}>
                    <div className='textAboutSrg fontSize17' id={`${vacancyPreview.group.id}`}>
                        {vacancyPreview.group.header}
                    </div>
                    {this.renderVacanciesPreview(vacancyPreview.group.vacancies)}
                </div>
            )
        })
    }

    renderVacanciesPreview = (vacancy: any) => {
        return vacancy.map((vacancy: any) => {
            return (
                <div className="vacancyContainer" key={`${vacancy.id}`}>
                    <div className="vacancyHeader">
                        {vacancy.name}
                    </div>
                    <div className="displayFlexVacancies">
                        <img src={vacancy.picture} height="80px" className="humanIcon" alt=""/>
                        <div className="displayBlock">
                            <div className="vacancyOption"><b>Опыт:&nbsp;</b>{vacancy.experience}</div>
                            <div className="vacancyOption"><b>Занятость:&nbsp;</b>{vacancy.employment}</div>
                            <div className="vacancyOption"><b>Задачи:</b></div>
                        </div>
                    </div>
                    <ul className="tasks">
                        {this.renderTasks(vacancy.tasks, vacancy.tasks.length)}
                    </ul>
                    <Button className="right buttonUi" basic color='blue' id={`${vacancy.id}`} onClick={this.openVacancy}>Посмотреть полностью</Button>
                </div>
            )
        })
    }

    renderTasks = (tasks: any, length: number) => {
        return tasks.map((task: any, i: number) => {
            if (i === length - 1) {
                return <li className="taskItem" key={i}>{task}.</li>
            } else {
                return <li className="taskItem" key={i}>{task};</li>
            }
        })
    }

    render() {
        return (
            <ErrorBoundary>
            <div className="main">
                <div className="vacancies">
                    {
                        this.isMenuVisible
                            ? (
                                <div className="exp">
                                    <div>
                                        <div><b className="developers" onClick={this.scroll}>#разработчики</b></div>
                                        <div><b className="product_owners" onClick={this.scroll}>#product_owners</b></div>
                                        <div><b className="analytics" onClick={this.scroll}>#аналитики</b></div>
                                    </div>
                                    {
                                        this.scrollTop ? <img src={upArrow} alt="upArrow" className="arrow" onClick={this.hideMenu} /> : null
                                    }
                                </div>
                            )
                            : this.scrollTop
                                ? <img src={downArrow} alt="downArrow" className="arrow onlyArrow" onClick={this.showMenu} />
                                : <div className="exp">
                                    <div>
                                        <div><b className="developers" onClick={this.scroll}>#разработчики</b></div>
                                        <div><b className="product_owners" onClick={this.scroll}>#product_owners</b></div>
                                        <div><b className="analytics" onClick={this.scroll}>#аналитики</b></div>
                                    </div>
                                    {
                                        this.scrollTop ? <img src={upArrow} alt="upArrow" className="arrow" onClick={this.hideMenu} /> : null
                                    }
                                </div>
                    }
                    <div className='vacancyHeader'>Мы растем и ищем таланты!</div>
                    {this.renderVacanciesGroups(this.vacanciesGroups)}
                    <div className='textAboutSrg'>
                        Ничего не подошло? Иди на &nbsp;
                        <span onClick={this.openHh} className="hh">HeadHunter</span>,
                        там вся самая актуальная информация.
                    </div>
                    <UpArrow />
                </div>
            </div>
            </ErrorBoundary>
        )
    }
}

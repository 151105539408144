import React from 'react';
import Router from './components/containers/Router'
import './App.css';

export default class App extends React.Component {
    render() {
        return (
            <>
                <Router />
            </>
        );
    }
}

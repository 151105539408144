import * as React from "react";
import {Provider} from "mobx-react";
import {RouterStore, syncHistoryWithStore} from "mobx-react-router";
import {BrowserRouter as ReactRouter, Route} from "react-router-dom";

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import HomePage from "../HomePage/HomePage";
import Office from "../Office/Office";
import Vacancies from "../Vacancies/Vacancies";
import Articles from "../Articles/Articles";
import Stack from "../Stack/Stack";
import Contacts from "../Contacts/Contacts";

import Vacancy45303749 from "../Vacancies/Vacancies/Vacancy45303749"
import Vacancy44768073 from "../Vacancies/Vacancies/Vacancy44768073"
import Vacancy44973233 from "../Vacancies/Vacancies/Vacancy44973233"
import Vacancy42344467 from "../Vacancies/Vacancies/Vacancy42344467"
import Vacancy44792739 from "../Vacancies/Vacancies/Vacancy44792739"
import Vacancy43447117 from "../Vacancies/Vacancies/Vacancy43447117"
import Vacancy44107302 from "../Vacancies/Vacancies/Vacancy44107302"
import Vacancy45244838 from "../Vacancies/Vacancies/Vacancy45244838"
import Vacancy44792131 from "../Vacancies/Vacancies/Vacancy44792131"

export default class Router extends React.Component {
    browserHistory = require("history").createBrowserHistory();
    routerStore = new RouterStore();
    history = syncHistoryWithStore(this.browserHistory, this.routerStore);

    render() {
        return (
            <Provider routerStore={this.routerStore} history={this.history}>
                <ReactRouter>
                    <Header />
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/articles" component={Articles} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route exact path="/office" component={Office}/>
                    <Route exact path="/stack" component={Stack} />
                    <Route exact path="/vacancies" component={Vacancies} />
                    <Route exact path="/vacancies/45303749" component={Vacancy45303749} />
                    <Route exact path="/vacancies/44768073" component={Vacancy44768073} />
                    <Route exact path="/vacancies/44973233" component={Vacancy44973233} />
                    <Route exact path="/vacancies/42344467" component={Vacancy42344467} />
                    <Route exact path="/vacancies/44792739" component={Vacancy44792739} />
                    <Route exact path="/vacancies/43447117" component={Vacancy43447117} />
                    <Route exact path="/vacancies/44107302" component={Vacancy44107302} />
                    <Route exact path="/vacancies/45244838" component={Vacancy45244838} />
                    <Route exact path="/vacancies/44792131" component={Vacancy44792131} />
                    <Footer />
                </ReactRouter>
            </Provider>
        );
    }
}

import * as React from "react";
import "../../Styles.scss";
import analyst from "../analyst.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy44107302 extends React.Component {

    vacancy: IVacancy = {
        id: '44107302',
        name: 'Senior Data Scientist/Lead Data Scientist',
        experience: '3 – 6 лет',
        employment: 'полная занятость, полный день',
        picture: analyst,
        itemGroups: [
            {
                header: 'Как мы работаем:',
                items: [
                    'Математика наших роботов в основном написана на Python с использованием различных алгоритмов машинного обучения: random forest, xgboost, catboost',
                    'Часть кода написана на Java',
                    'Для обработки больших данных применяется Hadoop и HBase, планируем использовать Spark',
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Знание Python или Java, или С++ (в области машинного обучения)',
                    'Знание стандартных алгоритмов, структур данных, математики',
                    'Знание современных методов машинного обучения (в т.ч. кластеризации, классификации, регрессии)',
                    'Нацеленность на решение амбициозных задач',
                ]
            },
            {
                header: 'Будет плюсом:',
                items: [
                    'Опыт написания алгоритмов обработки текстов',
                    'Опыт работы с Hadoop, HBase или другими системами обработки больших данных',
                    'Опыт работы с системами геокодирования',
                    'Желание делиться опытом, доносить знания до коллег'
                ]
            },
            {
                header: 'Задачи:',
                items: [
                    'Разработка архитектурных решений, подбор нужных алгоритмов для улучшения качества автоматической оценки недвижимости:' +
                    ' задачи регрессии, задачи классификации, кластеризационные алгоритмы',
                    'Обработка данных с использованием методов машинного обучения, включая обработку больших' +
                    ' данных с использованием Hadoop, HBase, Spark',
                    'Разработка и внедрение NLP алгоритмов',
                    'Доведение исследований до продуктовых решений и их поддержка'
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Оформление по ТК РФ',
                    'Заработная плата и размер ежеквартальных премий обсуждаются с успешным кандидатом',
                    'Офис БЦ "Пальмира", м. Бауманская/Электрозаводская',
                    'Гибкое начало рабочего дня',
                    'Дружеская атмосфера',
                ]
            }
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

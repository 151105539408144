import * as React from "react";
import "../../Styles.scss";
import analyst3 from "../analyst3.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy45244838 extends React.Component {

    vacancy: IVacancy = {
        id: '45244838',
        name: 'Data analyst',
        experience: '1 – 3 года',
        employment: 'полная занятость, полный день',
        picture: analyst3,
        itemGroups: [
            {
                header: 'Как мы работаем:',
                items: [
                    'Математика наших роботов в основном написана на Python с использованием различных алгоритмов машинного обучения: random forest, xgboost, catboost',
                    'Часть кода написана на Java',
                    'Для обработки больших данных применяется Hadoop и HBase, планируем использовать Spark',
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Знание математики и отличное знание статистики',
                    'Отличное знание SQL, написание сложных запросов',
                    'Отличное знание Excel ( визуализация и анализ данных)',
                    'Понимать физический смысл данных и находить причины аномалий'
                ]
            },
            {
                header: 'Желательные навыки:',
                items: [
                    'Опыт работы с noSQL базами данных',
                    'Знакомство с Graphana, Kibana, PowerBI',
                    'Знание Python'
                ]
            },
            {
                header: 'Задачи:',
                items: [
                    'Построение аналитической отчетности в Excel, PowerBI, Python',
                    'Работа с большими объемами данных из SQL и noSQL баз',
                    'Построение взаимосвязей между различными показателями и поиск аномалий в данных',
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Оформление по ТК РФ',
                    'Заработная плата и размер ежеквартальных премий обсуждаются с успешным кандидатом',
                    'Офис БЦ "Пальмира", м. Бауманская/Электрозаводская',
                    'Гибкое начало рабочего дня',
                    'Дружеская атмосфера'
                ]
            },
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

import * as React from "react";
import "../../Styles.scss";
import seniorJava from "../senior-java.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy45303749 extends React.Component {
    openVacancy = (e: any) => {
        window.open(`https://hh.ru/vacancy/${e.target.id}`);
    }

    vacancy: IVacancy = {
        id: '45303749',
        name: 'Team Lead Java',
        experience: '3 - 6 лет',
        employment: 'полная занятость, полный день',
        picture: seniorJava,
        itemGroups: [
            {
                header: 'Задачи:',
                items: [
                    'Управление командой Java разработчиков: найм, развитие команды, поддержание среды, в которой хочется продуктивно трудиться',
                    'Детализация задач на основе бизнес постановок',
                    'Оценка задач и участие в формировании спринта',
                    'Решение архитектурных и технически сложных задач',
                    'Code review',
                    'Совершенствование процессов разработки',
                    'Развитие квалификации разработчиков',
                ]
            },
            {
                header: 'За что Вы будете отвечать:',
                items: [
                    'Принятые сроки и результат работы команды',
                    'Эффективность процесса разработки',
                    'Профессиональный уровень команды'
                ]
            },
            {
                header: 'Что мы используем:',
                items: [
                    'Основой наших продуктов является Java8 и Spring (в т.ч. SpringBoot, SpringData, SpringSecurity)',
                    'Математика наших роботов написана на Python с использованием различных алгоритмов машинного обучения',
                    'В качестве баз данных мы используем MySQL, MongoDB, Redis, ElasticSearch',
                    'Для обработки больших данных применяется Hadoop и HBase',
                    'В качестве средств мониторинга - Graphana, Kibana и Zabbix',
                    'Развертывание системы осуществляется с использованием Jenkins и Puppet',
                    'Web-часть взаимодействует через REST и использует ReactJS, Thymeleaf',
                    'Часть сервисов развернуты в Docker-контейнерах',
                ]
            },
            {
                header: 'Как мы работаем:',
                items: [
                    'У нас используется CD/CI с использованием Jenkins',
                    'В качестве системы внутреннего документооборота мы используем JIRA',
                    'Часть проектов используют Kanban, часть - Scrum',
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Более 3-х лет разрабатываете коммерческие продукты на Java',
                    'Вам интересно работать с BigData',
                    'Знаете и используете Spring, Spring-boot, Spring data, Hibernate, Redis, возможно, ещё и MongoDB и RabbitMQ',
                    'Можете писать и быстрые, и красивые запросы к базам данных и разбираетесь в индексах',
                    'Знаете, почему и когда нужно писать простой, расширяемый и производительный код',
                    'Владеете средствами коллективной разработки и сборки проектов (Mercurial, Gradle, Jirа, Jenkins)',
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Оформление по ТК РФ',
                    'Высокая белая заработная плата',
                    'Комфортная рабочая среда, собственные программные продукты, не заказная разработка',
                    'Гибкое начало рабочего дня (с 9 до 11ч)',
                    'Офис БЦ "Пальмира", м. Бауманская/Электрозаводская (гибридный формат работы)'
                ]
            },
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

import * as React from "react";
import "../Styles.scss";
import up from '../up-chevron.png'
import {action, makeObservable, observable} from "mobx";
import {observer} from "mobx-react";

@observer
export default class UpArrow extends React.Component {
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    constructor(props: any) {
        super(props)
        makeObservable(this);
    }

    @observable upArrow = false;

    @action handleScroll = () => {
        if (window.pageYOffset >= 200) {
            this.upArrow = true;
        } else {
            this.upArrow = false;
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            this.upArrow ? <img src={up} className="upArrow" alt="up-arrow" height="40px" onClick={this.scrollToTop} /> : null
        )
    }
}
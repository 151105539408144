import * as React from "react";
import "../Styles.scss";
import {Icon, Popup, Dropdown} from "semantic-ui-react";
import { NavLink } from 'react-router-dom';
import logo from '../logo2.png'
import ErrorBoundary from "../common/ErrorBoundary";


export default class Header extends React.Component {

    PopupNav = () => (
        <Popup
            trigger={<div className="burger"><Icon name="bars" /></div>}
            content={this.PopupNavList}
            on="click"
            position='bottom right'
        />
        )

    PopupNavList = () => {
        return (
        <Dropdown.Menu className='dropdownMenu'>
            <Dropdown.Item className='dropdownItem'>
                <span onClick={() => {
                    window.location.href = '/stack'
                }} className="headerTopListLinkSmall">стек и проекты</span>
            </Dropdown.Item>
            <Dropdown.Item className='dropdownItem'>
                <span onClick={() => {
                    window.location.href = '/vacancies'
                }} className="headerTopListLinkSmall">вакансии</span>
            </Dropdown.Item>
            <Dropdown.Item className='dropdownItem'>
                <span onClick={() => {
                    window.location.href = '/articles'
                }} className="headerTopListLinkSmall">статьи</span>
            </Dropdown.Item>
            <Dropdown.Item className='dropdownItem'>
                <span onClick={() => {
                    window.location.href = '/office'
                }} className="headerTopListLinkSmall">жизнь компании</span>
            </Dropdown.Item>
            <Dropdown.Item className='dropdownItem'>
                <span onClick={() => {
                    window.location.href = '/contacts'
                }} className="headerTopListLinkSmall">контакты</span>
            </Dropdown.Item>
        </Dropdown.Menu>
    )}

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <ErrorBoundary>
                <header className="header">
                    <nav className="headerTop">
                        <div className="headerTopList">
                            <NavLink onClick={this.scrollToTop} exact to='/'>
                                <img src={logo} alt="" height="45px" className="headerLogo" />
                            </NavLink>
                            <NavLink onClick={this.scrollToTop} className="linkHeader headerTopListLink" activeClassName='active' to='/stack'>стек и проекты</NavLink>
                            <NavLink onClick={this.scrollToTop} className="linkHeader headerTopListLink" activeClassName='active' to='/vacancies'>вакансии</NavLink>
                            <NavLink onClick={this.scrollToTop} className="linkHeader headerTopListLink" activeClassName='active' to='/articles'>статьи</NavLink>
                            <NavLink onClick={this.scrollToTop} className="linkHeader headerTopListLink" activeClassName='active' to='/office'>жизнь компании</NavLink>
                            <NavLink onClick={this.scrollToTop} className="linkHeader headerTopListLink" activeClassName='active' to='/contacts'>контакты</NavLink>
                            {this.PopupNav()}
                        </div>
                    </nav>
                    <div className="headerBottom"></div>
                </header>
            </ErrorBoundary>
        )
    }
}

import * as React from "react";
import "../../Styles.scss";
import productOwner3 from "../product-owner3.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy43447117 extends React.Component {

    vacancy: IVacancy = {
        id: '43447117',
        name: 'Product Owner',
        experience: '1 – 3 года',
        employment: 'полная занятость, полный день',
        picture: productOwner3,
        itemGroups: [
            {
                header: 'Обязанности:',
                items: [
                    'Разработка и управление стратегией развития IT продукта в рамках направления «Коммерческая недвижимость»',
                    'Постановка задач, участие в планировании спринтов, взаимодействие с разработчиками / внешними подрядчиками и пр',
                    'Соблюдение бюджетных показателей и выполнение поставленных целей, включая монетизацию направления, оптимизацию расходов, увеличение CSI и пр',
                    'Разработка стратегии маркетингового продвижения',
                    'Экспертное сопровождение процесса разработки, тестирование (при необходимости)',
                    'Проведение презентаций продукта, участие во внешних мероприятиях',
                ]
            },
            {
                header: 'Требования:',
                items: [
                    'Опыт работы Product Owner / Product Manager (CRM, WEB, MOB. APP) в В2В сегменте',
                    'Понимание рынка оценки недвижимости/банковских продуктов (желательно)',
                    'Понимание проектной работы и Agile',
                    'Аналитический склада ума, умение работать в режиме многозадачности',
                    'Знание Jira, Excel, PowerPoint',
                ]
            },
            {
                header: 'Условия:',
                items: [
                    'Оформление по ТК РФ',
                    'Высокая «Белая» заработная плата (оклад + премия), обсуждается на встрече',
                    'График работы 5/2, с 9:00 до 18:00',
                    'Сильная команда экспертов',
                    'Молодой дружный коллектив',
                    'Комфортный офис (БЦ "Пальмира") в шаговой доступности м. Бауманская/ Электрозаводская'
                ]
            }
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

import * as React from "react";
import "../../Styles.scss";
import juniorJava from "../junior-java.png";
import Vacancy, { IVacancy } from "./Vacancy";

export default class Vacancy44973233 extends React.Component {

    vacancy: IVacancy = {
        id: '43320883',
        name: 'Java разработчик',
        experience: '1 - 3 года',
        employment: 'полная занятость, полный день',
        picture: juniorJava,
        itemGroups: [
            {
                header: 'Задачи:',
                items: [
                    'Участие в разработке архитектуры системы и техническом проектировании',
                    'Оптимизация производительности и развитие серверной логики',
                    'Разработка новых продуктов компании в рамках экосистемы «Ипотека за 1 день»',
                ]
            },
            {
                header: 'Что мы используем:',
                items: [
                    'Основой наших продуктов является Java8 и Spring (в т.ч. SpringBoot, SpringData, SpringSecurity)',
                    'В качестве баз данных мы используем MySQL, MongoDB, Redis, ElasticSearch',
                    'В качестве стредств мониторинга - Grafana, Kibana и Zabbix',
                    'Развертывание системы осуществляется с использованием Jenkins и Puppet',
                    'Мы используем микросервисную архитектуру; сервисы взаимодействуют через REST',
                    'Часть сервисов развернуты в Docker-контейнерах',
                    'Для UI используется ReactJS'
                ]
            },
            {
                header: 'Как мы работаем:',
                items: [
                    'У нас используется CI/CD с использованием Jenkins',
                    'В качестве системы внутреннего документооборота мы используем JIRA',
                    'Часть проектов используют Kanban, часть - Scrum',
                ]
            },
            {
                header: 'Все, что мы хотим от Вас — уверенное владение Java и опыт работы с реляционными базами данных.',
            },
            {
                header: 'Условия:',
                items: [
                    'Оформление по ТК РФ',
                    'Заработная плата и размер ежеквартальных премий обсуждаются с успешным кандидатом',
                    'Офис БЦ "Пальмира", м. Бауманская/Электрозаводская',
                    'Гибкое начало рабочего дня (с 9 до 11ч)',
                    'Дружеская атмосфера',
                    'Реальная возможность профессионального и карьерного роста',
                    'Работа над собственными программными продуктами, не заказная разработка',
                    'Гибридный формат работы (несколько дней в офисе, несколько дней из дома)'
                ]
            },
        ]
    }

    render() {
        return (
            <Vacancy children={this.vacancy} />
        )
    }
}

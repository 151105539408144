import * as React from "react";
import "../Styles.scss";
import {Button} from 'semantic-ui-react';
import habrImg1 from './habrImg1.png'
import habrImg2 from './habrImg2.jpeg'
import habrImg3 from './habrImg3.jpeg'
import habrImg4 from './habrImg4.png'
import habrImg5 from './habrImg5.jpeg'
import habrImg6 from './habrImg6.jpeg'
import UpArrow from "../common/UpArrow";
import ErrorBoundary from "../common/ErrorBoundary";

interface Article {
    id: string,
    header: string,
    picture: any,
    pictureName: string,
    paragraphs: string[]
}

export default class Articles extends React.Component {
    props: Article;

    constructor(props: Article) {
        super(props);
        this.props = props;
    }

    openArticle = (e: any) => {
        window.open(`https://habr.com/ru/company/srg/blog/${e.target.id}`)
    }

    articles: Article[] = [
        {
            id: '462967',
            header: 'Хаки при работе с большим числом мелких файлов',
            picture: habrImg1,
            pictureName: 'habrImg1',
            paragraphs: [
                'Идея статьи родилась спонтанно из дискуссии в комментариях к статье «Кое-что об inode».',
                'Дело в том, что внутренней спецификой работы наших сервисов является хранение громадного числа мелких ' +
                'файлов. На данный момент у нас порядка сотен терабайт таких данных. И мы натолкнулись на некоторые ' +
                'очевидные и не очень грабельки и успешно по ним прошлись.',
                'Поэтому делюсь нашим опытом, может кому и пригодится.'
            ]
        },
        {
            id: '444102',
            header: 'Эволюция средств поставки, или размышления о Docker, deb, jar и прочем',
            picture: habrImg2,
            pictureName: 'habrImg2',
            paragraphs: [
                'Как-то в один момент я решил написать статью про поставку в виде контейнеров докер и deb-пакетов, ' +
                'но когда начал, меня почему-то понесло в далекие времена первых персональных компьютеров и даже ' +
                'калькуляторов. В общем, вместо сухих сравнений докера и deb получились вот такие вот размышления ' +
                'на тему эволюции, кои и представляю на Ваш суд.',
                'Любой продукт, какой бы он ни был, должен каким-то образом добраться до продуктовых ' +
                'cерверов, должен быть настроен и запущен. Вот об этом и будет эта статья.'
            ]
        },
        {
            id: '479696',
            header: 'Пара слов про Alter Table, или как делать не надо',
            picture: habrImg3,
            pictureName: 'habrImg3',
            paragraphs: [
                'Это скорее не статья, а небольшая заметка о некоторых особенностях работы с большими таблицами в MySQL.',
                'Причиной написания стало вроде бы будничное добавление новой колонки в таблицу. Но все оказалось ' +
                'не так просто, как предполагалось.'
            ]
        },
        {
            id: '446752',
            header: 'Трассировка сервисов, OpenTracing и Jaeger',
            picture: habrImg4,
            pictureName: 'habrImg4',
            paragraphs: [
                'В наших проектах мы используем микросервисную архитектуру. При возникновении узких мест ' +
                'в производительности достаточно много времени тратится на мониторинг и разбор логов. ' +
                'При логировании таймингов отдельных операций в лог-файл, как правило, сложно понять что ' +
                'привело к вызову этих операций, отследить последовательность действий или смещение во ' +
                'времени одной операции относительно другой в разных сервисах.',
                'Для минимизации ручного труда мы решили воспользоваться одним из инструментов ' +
                'трассировки. О том, как и для чего можно использовать трассировку и как это делали мы,' +
                'и пойдет речь в этой статье.'
            ]
        },
        {
            id: '439614',
            header: 'Как отличить хороший ремонт от плохого, или как мы в SRG сделали из Томита-парсера многопоточную Java-библиотеку',
            picture: habrImg5,
            pictureName: 'habrImg5',
            paragraphs: [
                'В этой статье речь пойдет о том, как мы интегрировали разработанный Яндексом Томита-парсер в нашу ' +
                'систему, превратили его в динамическую библиотеку, подружили с Java, сделали многопоточной и решили ' +
                'с её помощью задачу классификации текста для оценки недвижимости.'
            ]
        },
        {
            id: '459722',
            header: 'Что общего у командной разработки и альпинизма',
            picture: habrImg6,
            pictureName: 'habrImg6',
            paragraphs: [
                'Я разработчик, альпинист и мне небезразлично все, что происходит вокруг. В этой статье я хочу ' +
                'рассказать о своих размышлениях по поводу командной работы, которые родились после моего ' +
                'восхождения на вершину Монблана в Альпах.',
                'Раньше я не проводила параллелей между работой и своим увлечением альпинизмом и горными походами. ' +
                'В горах я отдыхала от работы, и после отпуска в суровых аскетичных условиях, после большой ' +
                'физической нагрузки и полной перезагрузки мозга, я с удовольствием возвращалась на работу.'
            ]
        }
    ];

    renderArticles = (articles: Article[]) => {
        return articles.map((article: Article, i: number) => {
            return (
                <div className="articleContainer" key={article.id}>
                    <div className="articleHeader">{article.header}</div>
                    <div className="articleText">
                        {(article.pictureName === 'habrImg1') ? (
                                <>
                                    <p>{article.paragraphs[0]}</p>
                                    <img src={article.picture} className={article.pictureName} alt=""/>
                                    <p>{article.paragraphs[1]}</p>
                                    <p>{article.paragraphs[2]}</p>
                                </>
                            ) : (
                            <div className="articleText displayFlex">
                                {(i % 2 === 0) ? (
                                    <>
                                        <div className='displayBlock'>
                                            {this.renderText(article.paragraphs)}
                                        </div>
                                        <img src={article.picture} className={`${article.pictureName} habrImg`} alt=""/>
                                    </>
                                ) : (
                                    <>
                                        <img src={article.picture} className={`${article.pictureName} habrImg`} alt=""/>
                                        <div className='displayBlock'>
                                            {this.renderText(article.paragraphs)}
                                        </div>
                                    </>
                                    )
                                }
                            </div>
                        )}
                    </div>
                    <Button className="right buttonUi" basic color='blue' id={article.id} onClick={this.openArticle}>Читать</Button>
                </div>
            )
        })
    }

    renderText = (paragraphs: any) => {
        return paragraphs.map((paragraph: string, i: number) => {
            return <p key={i}>{paragraph}</p>
        })
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="main">
                    <div className="text marginBottom">Однажды мы решили, что мы хотим иметь свой блог на Хабре, поскольку
                        нам есть чем поделиться с сообществом. Потом официальный блог мы перестали вести, поскольку
                        мы больше пишем код, нежели статьи.
                        <br/>
                        Но вот то, что мы успели написать в те времена:</div>
                    <div className="articles">
                        {this.renderArticles(this.articles)}
                        <UpArrow />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

import ImageGallery from 'react-image-gallery';
import * as React from "react";
import "../../Styles.scss";
import people1full from '../photos/people/people1full.jpg';
import people1small from '../photos/people/people1small.jpg';
import people2full from '../photos/people/people2full.jpg';
import people2small from '../photos/people/people2small.jpg';
import people3full from '../photos/people/people3full.jpg';
import people3small from '../photos/people/people3small.jpg';
import people4full from '../photos/people/people4full.jpg';
import people4small from '../photos/people/people4small.jpg';
import people5full from '../photos/people/people5full.jpg';
import people5small from '../photos/people/people5small.jpg';
import people6full from '../photos/people/people6full.jpg';
import people6small from '../photos/people/people6small.jpg';
import people7full from '../photos/people/people7full.jpg';
import people7small from '../photos/people/people7small.jpg';
import people8full from '../photos/people/people8full.jpg';
import people8small from '../photos/people/people8small.jpg';
import people9full from '../photos/people/people9full.jpg';
import people9small from '../photos/people/people9small.jpg';
import people10full from '../photos/people/people10full.jpg';
import people10small from '../photos/people/people10small.jpg';
import ErrorBoundary from "../../common/ErrorBoundary";


export default class ImageGalleryPeople extends React.Component {
    images = [
        {
            original: people1full,
            thumbnail: people1small,
        },
        {
            original: people2full,
            thumbnail: people2small,
        },
        {
            original: people3full,
            thumbnail: people3small,
        },
        {
            original: people4full,
            thumbnail: people4small,
        },
        {
            original: people5full,
            thumbnail: people5small,
        },
        {
            original: people6full,
            thumbnail: people6small,
        },
        {
            original: people7full,
            thumbnail: people7small,
        },
        {
            original: people8full,
            thumbnail: people8small,
        },
        {
            original: people9full,
            thumbnail: people9small,
        },
        {
            original: people10full,
            thumbnail: people10small,
        },

    ];

    render() {
        return (
            <ErrorBoundary>
                <ImageGallery items={this.images} />
            </ErrorBoundary>
        )
    }
}

import React, { Component, ErrorInfo } from "react";
import {Icon, Message} from "semantic-ui-react";

interface Props {
    children: any;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Message negative icon>
                    <Icon name="warning" />
                    <Message.Content>
                        <Message.Header>Что-то пошло не так :(</Message.Header>
                    </Message.Content>
                </Message>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
